// slot-backend1.dev-rocket.ch
//const BACKEND_URL = 'http://rockettest.planb.ch/api';
//const BACKEND_URL = 'https://slot-backend1.dev-rocket.ch/api';
const BACKEND_URL = 'https://slotmachine-backend.globi-glace.ch/api';
//const BACKEND_URL = 'http://localhost/api'

function getUrl(route) {
  return BACKEND_URL + '/' + route;
}

/**
 *  fetch with timeout
 * fetch API wrapper, enables the the usage of an additional timeout
 * @param {*} resource
 * @param {*} options
 */
async function fetchWithTimeout(resource, options) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

/**
 * POST request to given url with given body
 * @param {*} url
 * @param {*} data
 */
const POST = async (url, data) => {
  const response = await fetchWithTimeout(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    /* TODO: not working with a backend not answering OPTIONS requests
        headers: {
            'Content-Type': 'application/json',
        },
        */
    body: data,
  }).then((response) => response.json());
  return response;
};

/**
 * Route randomwDraw
 * @param {*} userData JSON form data
 */
export function randomDraw(userData) {
  const apiUrl = getUrl('draw');
  return POST(apiUrl, userData);
}

/**
 * Route checkUser
 * @param {email} userData JSON form data
 */
export function checkUser(userData) {
  const apiUrl = getUrl('check');
  return POST(apiUrl, userData);
}
