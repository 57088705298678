import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PopUp from './Popup';

export default function Form(props) {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => props.onSubmit(data);
  const { t } = useTranslation();
  const [seen, setSeen] = useState(false);

  function clickSubmitWrapper(checked) {
    var input = document.getElementById('agbcheckbox');
    if (checked !== input.checked) {
      input.click();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class='form-mother-wrapper'>
        <div class='input-wrapper'>
          <div class='name-wrapper'>
            <input
              name='vorname'
              placeholder={t('form.vorname')}
              ref={register({ required: true })}
            />
            {errors.vorname && <p class='error'>{t('form.required')}</p>}
          </div>
          <div class='name-wrapper'>
            <input
              name='name'
              placeholder={t('form.name')}
              ref={register({ required: true })}
            />
            {errors.name && <p class='error'>{t('form.required')}</p>}
          </div>
        </div>
        <input
          type='email'
          name='email'
          placeholder={t('form.email')}
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '',
            },
          })}
        />
        {errors.email && <p class='error'>{t('form.emailreq')}</p>}
        <div class='input-wrapper'>
          <div class='adresse-wrapper'>
            <input
              name='strasse_nr'
              placeholder={t('form.strasse')}
              ref={register({ required: true })}
            />
            {errors.strasse_nr && <p class='error'>{t('form.required')}</p>}
          </div>
          <div class='plz-wrapper'>
            <input
              name='plz_ort'
              placeholder={t('form.ort')}
              ref={register({ required: true })}
            />
            {errors.plz_ort && <p class='error'>{t('form.required')}</p>}
          </div>
        </div>
        <div class='submit-mother-wrapper'>
          <div class='agb-wrapper'>
            <div class='round'>
              <input
                name='agb'
                id='agbcheckbox'
                type='checkbox'
                ref={register({ required: true })}
              />
              <label for='agbcheckbox'></label>
            </div>
            <div class='agblabel' onClick={clickSubmitWrapper}>
              <span class='disclaimer' id='popup'>
                {t('form.teilnahmebedingungen1')}&nbsp;
                <a onClick={() => setSeen(!seen)}>
                  {t('form.teilnahmebedingungen2')}&nbsp;
                </a>
                {t('form.teilnahmebedingungen3')}
              </span>
            </div>
          </div>
          {errors.agb && <p class='error'>{t('form.required')}</p>}
        </div>
        <input type='submit' value={t('form.submit')} />
      </div>
      {seen ? <PopUp toggle={() => setSeen(!seen)} /> : null}
    </form>
  );
}
