import React from 'react';
import Notification from './Notification';
import GlobiStart from '../images/globi_start.svg';
import GlobiSlot from '../images/globi_slot.svg';
import GlobiWon from '../images/globi_won.svg';

export default function Content(props) {
  const { priceImage, winner } = props;
  const winning = winner > -1 ? true : false;
  var link;
  var alt;

  if (winning) {
    link = process.env.PUBLIC_URL + priceImage;
    alt = 'Preis';
    document.querySelector('#root').className = 'win';
  } else {
    document.querySelector('#root').className = 'lost';
  }

  return (
    <section id='content'>
      <div className='leftContent'>
        <div class='leftContent-inner'>
          <img src={link} alt={alt} />
        </div>
      </div>
      <div className='middleContent'>
        <div class='notification-wrapper'>
          <Notification {...props} />
        </div>
      </div>
      <div class='footerright-wrapper'>
        <div class='footerright-inner'>
          <img class='footerright start' src={GlobiStart} alt='Globi' />
          <img class='footerright slot' src={GlobiSlot} alt='Globi' />
          <img class='footerright win' src={GlobiWon} alt='Globi' />
        </div>
      </div>
    </section>
  );
}
