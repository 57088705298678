import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Notification(props) {
  const { winner, priceName } = props;
  var price = priceName;
  if (price === 'Globi Quartett') {
    price = 'Das Globi Quartett';
  }
  // if (price === 'Globi Quartett2') {
  //   price = 'Das Globi Quartett2';
  // }
  if (
    document.querySelector('body').classList.contains('FR') &&
    price === 'Das Globi Quartett'
  ) {
    price = 'Le jeu des 7 Familles';
  }
  // else if (
  //   document.querySelector('body').classList.contains('FR') &&
  //   price === 'Globi Quartett2'
  // ) {
  //   price = '[FR] Globi Quartett2';
  // }
  const { t, i18n } = useTranslation();

  const winning = winner > -1 ? true : false;
  let block1;
  let icon;
  let block2 = '';
  let block3 = '';
  if (winning) {
    block1 = <h2>{t('win.title')}</h2>;
    icon = <FontAwesomeIcon icon='smile' size='win' />;
    if (i18n.exists('win.text1')) {
      block2 = (
        <span className='bold wintext'>
          {price}
          {t('win.text1')}
        </span>
      );
    }
    if (i18n.exists('win.text2')) {
      block3 = <span className='wintext'>{t('win.text2')}</span>;
    }
  } else {
    block1 = <h2>{t('loose.title')}</h2>;
    icon = <FontAwesomeIcon icon='frown' size='loose' />;
    if (i18n.exists('loose.text1')) {
      block2 = <span className='bold wintext'>{t('loose.text1')}</span>;
    }
    if (i18n.exists('loose.text2')) {
      block3 = <span className='wintext'>{t('loose.text2')}</span>;
    }
  }
  return (
    <section id='notification'>
      <span id='message'>
        {block1}
        {icon}
        <Fragment>
          {block2}
          {block3}
        </Fragment>
      </span>
    </section>
  );
}
