import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PopUpText(props) {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t('popup.p1')}</p>
      <ul>
        <li>
          <h3>{t('popup.list1')}</h3>
          <p>{t('popup.list1text1')}</p>
          <p>{t('popup.list1text2')}</p>
          <p>{t('popup.list1text3')}</p>
          <p>{t('popup.list1text4')}</p>
        </li>
        <li>
          <h3>{t('popup.list2')}</h3>
          <p>{t('popup.list2text1')}</p>
        </li>
        <li>
          <h3>{t('popup.list3')}</h3>
          <p>{t('popup.list3text1')}</p>
          <p>{t('popup.list3text2')}</p>
          <p>
            {t('popup.list3text3part1')}
            <a
              href='https://globi-glace.ch/gewinnen durchgeführt'
              target='_blank'
            >
              www.globi-glace.ch/gewinnen
            </a>{' '}
            {t('popup.list3text3part2')}
          </p>
          <p>
            {t('popup.list3text4part1')}
            <a href='mailto:globi-glace@emmi.com'>globi-glace@emmi.com</a>{' '}
            {t('popup.list3text4part2')}
          </p>
          <p>{t('popup.list3text5')}</p>
          <p>{t('popup.list3text6')}</p>
          <p>{t('popup.list3text7')}</p>
          <p>{t('popup.list3text8')} </p>
          <p>{t('popup.list3text9')}.</p>
        </li>
      </ul>
    </div>
  );
}
