import React from 'react';
// import Globi from '../images/Globi.svg';
import { useTranslation } from 'react-i18next';

export default function Footer(props) {
  const { t } = useTranslation();

  return (
    <footer>
      <p>{t('footer_bottom.text')}</p>
    </footer>
  );
}
