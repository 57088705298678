import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobiStart from '../images/globi_start.svg';
import GlobiSlot from '../images/globi_slot.svg';
import GlobiWon from '../images/globi_won.svg';

export default function Ad(props) {
  const { t } = useTranslation();

  if (document.querySelector('.go-was-pressed') !== null) {
    document.querySelector('.go-was-pressed').style.display = 'none';
  }

  return (
    <div class='App ad-wrapper'>
      <img class='footerright start' src={GlobiStart} alt='Globi' />
      <img class='footerright slot' src={GlobiSlot} alt='Globi' />
      <img class='footerright win' src={GlobiWon} alt='Globi' />
      <div class='ad'>
        <h2>{t('footer.title')}</h2>
        <p>{t('footer.text1')}</p>
        <p>{t('footer.text2')}</p>
      </div>
      <div class='ad-2'>
        <h2>{t('footer2.title')}</h2>
        <p>{t('footer2.text')}</p>
      </div>
    </div>
  );
}
