import React from "react";
import Spinner from './Spinner';

/**
 * Base Slotmachine class (heavily inspired by Andy Hoffmann).
 * But instead of a random draw, we changed it to display the calculated backend results.
 */
export default class Slotmachine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            winner: null,
            start: false
        }
        this.finishHandler = this.finishHandler.bind(this);
    }

    /**
     * currently just here to make sure that the slotmachine displays what it is intended to.
     */
    static icons = [
        "hell",
        "blau",
        "dunkel",
    ]

    static matches = [];

    /**
     * Finish Callback.
     * Called by each Spinner component.
     * @param {*} value ID of icon
     */
    finishHandler(value) {
        Slotmachine.matches.push(value);

        if (Slotmachine.matches.length === 3) {
            Slotmachine.matches = []
        }
    }

     /**
     * componentDidUpdate Lifecycle Function.
     * Called everytime the parent component updates the props.
     * We used it to delegate the winner icon or set random user icons.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (this.props.start === true && prevProps.start === false) {
            this._child1.componentStart();
            this._child2.componentStart();
            this._child3.componentStart();
        }
        if (prevProps.winner !== this.props.winner) {
            const winner = this.props.winner
            // show random item combination (choose without repetition)
            if (winner === -1) {
                const icons = Slotmachine.icons
                // get three random and different icons
                const randomIndexes = [...Array(icons.length).keys()].sort(() => (
                    0.5 - Math.random()
                )).slice(0, 3)
                console.log(randomIndexes)
                this.setState({
                    winner1: randomIndexes[0],
                    winner2: randomIndexes[1],
                    winner3: randomIndexes[2],
                })
            } else {
                // win: same icon for all elements
                const winElement = this.props.winner
                this.setState({
                    winner1: winElement,
                    winner2: winElement,
                    winner3: winElement
                })
            }
        }
    }

    /**
     * Slotmachine render function.
     * Holds the individual Spinners and some styling structure.
     */
    render() {
        return (
                <div class="spinner-wrapper">
                <div className={`spinner-container`}>
                    <Spinner
                        onFinish={this.finishHandler}
                        icons={Slotmachine.icons}
                        winner={this.state.winner1}
                        ref={(child) => { this._child1 = child; }}
                        timer="1200"
                    />
                    <Spinner
                        onFinish={this.finishHandler}
                        icons={Slotmachine.icons}
                        winner={this.state.winner2}
                        ref={(child) => { this._child2 = child; }}
                        timer="800"
                    />
                    <Spinner
                        onFinish={this.finishHandler}
                        icons={Slotmachine.icons}
                        winner={this.state.winner3}
                        ref={(child) => { this._child3 = child; }}
                        timer="2200"
                    />
                    <div className="gradient-fade"></div>
                </div>
                </div>
        );
    }
}