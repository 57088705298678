import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Sorry() {
  const { t } = useTranslation();

  let block1;
  let icon;
  let block2 = '';

  block1 = <h2>{t('sorry.title')}</h2>;
  icon = <FontAwesomeIcon icon='frown' size='loose' />;
  block2 = <span className='wintext'>{t('sorry.text')}</span>;

  if (document.querySelector('.go-was-pressed') !== null) {
    document.querySelector('.go-was-pressed').style.display = 'none';
  }

  return (
    <section id='content'>
      <div className='leftContent'></div>
      <div className='middleContent'>
        <div class='notification-wrapper'>
          <section id='notification'>
            <span id='message'>
              {block1}
              {icon}
              {block2}
            </span>
          </section>
        </div>
      </div>
      <div className='rightContent'></div>
    </section>
  );
}
