import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function FormHeader(props) {
	const { t } = useTranslation();
    return (
        <Fragment>
            <h1>{t("start.title")}</h1>
            <h2>{t("start.subtitle")}</h2>
        </Fragment>
    )

}