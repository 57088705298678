import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../images/logo.svg';

function setLanguage(lang) {
  if (lang === 'fr') {
    document.querySelector('body').className = 'FR';
    document.querySelector('.lngswitch.lng-FR').style.display = 'none';
    document.querySelector('.lngswitch.lng-DE').style.display = 'block';
  }
  if (lang === 'de') {
    document.querySelector('body').className = 'DE';
    document.querySelector('.lngswitch.lng-DE').style.display = 'none';
    document.querySelector('.lngswitch.lng-FR').style.display = 'block';
  }
}

export default function LogoHeader(props) {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  // initially prepare the button
  useEffect(() => {
    setLanguage(currentLang);
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <header>
      <div className='App'>
        <a href='#' class='logo-wrapper'>
          <img src={logo} alt='logo' />
        </a>
        <Fragment>
          <span
            title='Français'
            className='lngswitch lng-FR'
            onClick={() => changeLanguage('fr')}
          >
            F
          </span>
          <span
            title='Deutsch'
            className='lngswitch lng-DE'
            onClick={() => changeLanguage('de')}
          >
            D
          </span>
        </Fragment>
      </div>
    </header>
  );
}
