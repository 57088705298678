import React from "react";
import Slotmachine from "./Slotmachine";

/**
 * Spinner Component.
 * Stripped version of Andrew's implementation.
 * Displays and animates the sprite image.
 * The animation is entirely done by a background image movement, styled with css.
 */
export default class Spinner extends React.Component {

    static iconHeight = 240;
    random = Math.random()
    multiplier = Math.floor(this.random * (4 - 1) + 1);
    start = this.setStartPosition();
    speed = Spinner.iconHeight * this.multiplier;

    constructor(props) {
        super(props);
        this.state = {
            position: 0,
            lastPosition: null
        }
    };

    setStartPosition() {
        // choose random start position
        const randomStart = Math.random()
        return ((Math.floor((randomStart * 9))) * Spinner.iconHeight) * -1;
    }

    /**
     * Adapts the background position to move the icons.
     */
    moveBackground() {
        this.setState({
            position: this.state.position - this.speed,
            timeRemaining: this.state.timeRemaining - 100
        })
    }

    componentDidMount() {
        clearInterval(this.timer);

        this.setState({
            position: this.start,
            timeRemaining: this.props.timer
        });
    }

    componentStart() {
        this.timer = setInterval(() => {
            this.moveBackground()
        }, 100);
    }


    /**
     * As soon as the server responds, the winning icon is set or we have a loser.
     * We just have to display the correct icon accordingly.
     * @param {A} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (prevProps.winner !== this.props.winner) {
            clearInterval(this.timer);
            let { winner } = this.props
            //console.log(Slotmachine.icons[winner])
            const newPosition = (1 - ((winner /*-1 for middle */) * Spinner.iconHeight)) //+ 2 * Spinner.iconHeight
            this.setState({
                position: newPosition,
            });
        }

    }

    /**
     * simple render function.
     * div as image holder, with inline backgroundPosition and some callbacks.
     * The finishCallback is subscribed to the onTransitionEnd event.
     */
    render() {
        let { position } = this.state;
        return (
            <div
                onTransitionEnd={() => this.props.onFinish()}
                style={{ backgroundPosition: '0px ' + position + 'px' }}
                className={`icons`}
            />
        )
    }
}