import React, { Component } from 'react';
import PopUpText from './PopUpText';

export default class PopUp extends Component {
  handleClick = () => {
    this.props.toggle();
  };
  render() {
    return (
      <div className='popup-wrapper'>
        <div className='popup-text'>
          <span className='close' onClick={this.handleClick}>
            &times;{' '}
          </span>
          <PopUpText />
        </div>
      </div>
    );
  }
}
