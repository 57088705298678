import './App.css';
import React from 'react';
import Slotmachine from './components/slotmachine/Slotmachine';
import Header from './components/Header';
import Header2 from './components/Header2';
import Form from './components/Form';

import * as RestManager from './managers/RestManager';
import LogoHeader from './components/LogoHeader';
import Ad from './components/Ad';
import Footer from './components/Footer';
import Content from './components/Content';
import Sorry from './components/Sorry';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSmile, faFrown, faCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faSmile, faFrown, faCircle);

const TIMEOUT = 3000;

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isSubmitted: false,
      isGo: false,
      isSorry: false,
      winner: undefined,
      priceName: undefined,
      priceImage: undefined,
      formdata: undefined,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() { }
  /**
   * Form onSubmit callback.
   * @param {F} data form data object.
   * Checks if there is the field price inside the response.
   * If yes, the user won a price. Sets the states accordingly.
   */
  onSubmit(data) {
    const formData = JSON.stringify(data);
    RestManager.checkUser(formData)
      .then((response) => {
        // amount of daily trials is set on the server side
        if (response.teilgenommen) {
          this.setState({
            isSorry: true,
          });
        } else {
          this.setState({
            isSubmitted: true,
            formdata: data,
          });
          document.querySelector('#root').className = 'slotmachine';
        }
      })
      .catch((e) => {
        // catch any error, return lost.
        console.log(e);
      });
  }

  /**
   * Function called when user hits Go.
   */
  go(data) {
    this.setState({
      isGo: true,
    });
    const formData = JSON.stringify(data);
    RestManager.randomDraw(formData)
      .then((response) => {
        if ('preis' in response) {
          const priceId = response.preis.id;
          const priceName = response.preis.name;
          const priceImage = response.preis.image;
          this.win(priceId, priceName, priceImage);
        } else {
          this.lost();
        }
      })
      .catch((e) => {
        // catch any error, return lost.
        console.log(e);
        this.lost();
      });
  }

  /**
   * Sets the win state.
   * We use the timeout to let the slotmachine spin a bit longer.
   * @param {*} priceId
   * @param {*} priceName
   */
  win(priceId, priceName, priceImage) {
    setTimeout(() => {
      this.setState({
        winner: priceId,
        priceName: priceName,
        priceImage: priceImage,
      });
    }, TIMEOUT);
  }

  /**
   * Sets the lose state.
   * We use the timeout to let the slotmachine spin a bit longer.
   */
  lost() {
    setTimeout(() => {
      this.setState({
        winner: -1,
        priceName: undefined,
        priceImage: undefined,
      });
    }, TIMEOUT);
  }

  /**
   * Base render function.
   * Display all components needed, shows and hides additional components according to states.
   */
  render() {
    const { winner, priceName, priceImage, formdata, isGo } = this.state;
    let gobutton;

    // TODO: very ugly, even the conditions
    if (this.state.isSubmitted === true && this.state.isGo === false) {
      gobutton = (
        <div className='App go-button'>
          <button type='go' onClick={() => this.go(formdata)}>
            GO!
          </button>
        </div>
      );
    } else {
      // gobutton = '';
      gobutton = (
        <div className='App go-was-pressed'>
          <button type='go'>GO!</button>
        </div>
      );
    }

    return (
      <div class='content-wrap'>
        <LogoHeader />
        <div className='App'>
          {!this.state.isSubmitted && (
            <div class='app-subdiv'>
              <Header />
              <Form onSubmit={this.onSubmit} />
            </div>
          )}
          {this.state.isSubmitted && (
            <div class='slotmachine-mother-wrapper'>
              <Header2 />
              <div class='slotmachine-wrapper'>
                <Slotmachine winner={winner} start={isGo} onFinish={() => { }} />
                {gobutton}
              </div>
            </div>
          )}
          {Boolean(winner) && (
            <Content
              winner={winner}
              priceName={priceName}
              priceImage={priceImage}
            />
          )}
        </div>
        {this.state.isSorry ? <Sorry /> : <Ad />}
        <Footer />
      </div>
    );
  }
}
