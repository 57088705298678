import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function FormHeader(props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h1>{t('slotmachine.title')}</h1>
      <h2>{t('slotmachine.subtitle')}</h2>
    </Fragment>
  );
}
